// withTranslation

import { AxiosResponse } from 'axios'
import produce from 'immer'

import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { ContactPersonsListActionTypes } from '@app/store/pages/clients/contactPersonsList/contactPersonsList.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'

import * as actions from '@app/store/pages/clients/contactPersonsList/contactPersonsList.actions'

import {
  selectContactPersonsListFilters,
  selectContactPersonsListLimit,
  selectContactPersonsListOrderBy,
  selectContactPersonsListOrderDirection,
  selectContactPersonsListPage,
} from '@app/store/pages/clients/contactPersonsList/contactPersonsList.selectors'

import * as DTOs from '@shared/dto'

function* getContactPersonsListDataSaga(page: number) {
  try {
    const limit = selectContactPersonsListLimit(yield select())
    const orderDirection = selectContactPersonsListOrderDirection(
      yield select(),
    )
    const filters = selectContactPersonsListFilters(yield select())
    const orderBy = selectContactPersonsListOrderBy(yield select())
    const operator = selectSelectedOperator(yield select())

    if (!operator) {
      throw new Error('Operator is required')
    }

    const params: DTOs.GetCPQuery = {
      ...filters,
      orderBy,
      orderDirection: orderDirection ?? 'DESC',
      limit,
      page,
    }

    const {
      data,
    }: AxiosResponse<DTOs.PaginatedList<DTOs.ContactPersonDetailDto>> =
      yield call(api.listContactPersons, params)

    yield put(actions.getContactPersonsListSuccessAction(data))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getContactPersonsList.default')
      default: 'errors.getContactPersonsList.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getContactPersonsListFailureAction(error))
  }
}

function* getInitialContactPersonsListDataSaga() {
  yield getContactPersonsListDataSaga(1)
}

function* getMoreContactPersonsListDataSaga() {
  const page = selectContactPersonsListPage(yield select())

  yield getContactPersonsListDataSaga(page + 1)
}

function* setContactPersonsListFiltersSaga(
  action: ReturnType<typeof actions.setContactPersonsListFiltersAction>,
) {
  try {
    const { requestDebounceInMilliseconds } = action.payload

    const { filtersChanged } = yield race({
      filtersChanged: take(
        ContactPersonsListActionTypes.SetContactPersonsListFilters,
      ),
      timeout: delay(requestDebounceInMilliseconds),
    })

    if (filtersChanged) {
      return
    }

    yield put(actions.getContactPersonsListDataFirstPageAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getContactPersonsListFailureAction(error))
  }
}

function* setContactPersonsListSortSaga() {
  try {
    yield put(actions.getContactPersonsListDataFirstPageAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getContactPersonsListFailureAction(error))
  }
}

function* reloadContactPersonsListSaga() {
  const limit = selectContactPersonsListLimit(yield select())
  const page = selectContactPersonsListPage(yield select())
  const orderDirection = selectContactPersonsListOrderDirection(yield select())
  const filters = selectContactPersonsListFilters(yield select())
  const orderBy = selectContactPersonsListOrderBy(yield select())
  const operator = selectSelectedOperator(yield select())

  if (!operator) {
    throw new Error('Operator is required')
  }

  const params: DTOs.GetCPQuery = {
    ...filters,
    orderBy,
    orderDirection: orderDirection ?? 'DESC',
    limit: limit * page,
    page: 1,
  }

  try {
    const {
      data,
    }: AxiosResponse<DTOs.PaginatedList<DTOs.ContactPersonDetailDto>> =
      yield call(
        api.listContactPersons,
        produce(params, (draft) => {
          if (draft.limit && draft.page) {
            draft.limit = draft.limit * draft.page
          }
        }),
      )

    yield put(actions.reloadContactPersonsListSuccessAction(data))
  } catch (error) {
    console.error(error)

    yield put(actions.getContactPersonsListFailureAction(error))
  }
}

export default function* watchContactPersonsListSaga(): Generator {
  yield takeLatest(
    ContactPersonsListActionTypes.GetContactPersonsListDataFirstPage,
    getInitialContactPersonsListDataSaga,
  )

  yield takeLatest(
    ContactPersonsListActionTypes.GetContactPersonsListDataNextPage,
    getMoreContactPersonsListDataSaga,
  )

  yield takeLatest(
    ContactPersonsListActionTypes.ReloadContactPersonsList,
    reloadContactPersonsListSaga,
  )

  yield takeLatest(
    ContactPersonsListActionTypes.SetContactPersonsListFilters,
    setContactPersonsListFiltersSaga,
  )

  yield takeLatest(
    ContactPersonsListActionTypes.SetContactPersonsListSort,
    setContactPersonsListSortSaga,
  )
}

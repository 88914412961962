import { action } from 'typesafe-actions'

import { AuthActionTypes } from '@app/store/core/auth/auth.constants'

import * as DTOs from '@shared/dto'

export const logoutAction = () => action(AuthActionTypes.Logout)

export const logoutSuccessAction = () => action(AuthActionTypes.LogoutSuccess)

export const logoutFailureAction = (error: unknown) =>
  action(AuthActionTypes.LogoutFailure, error)

export const logoutFromAllDevicesAction = (token: string) =>
  action(AuthActionTypes.LogoutFromAllDevices, { token })

export const logoutFromAllDevicesSuccessAction = () =>
  action(AuthActionTypes.LogoutFromAllDevicesSuccess)

export const logoutFromAllDevicesFailureAction = (error: unknown) =>
  action(AuthActionTypes.LogoutFromAllDevicesFailure, error)

export const requestPasswordChangeAction = (
  data: Omit<DTOs.RequestResetPasswordDto, 'reset_url'>,
) => action(AuthActionTypes.RequestPasswordChange, { data })

export const requestPasswordChangeSuccessAction = () =>
  action(AuthActionTypes.RequestPasswordChangeSuccess)

export const requestPasswordChangeFailureAction = (error: unknown) =>
  action(AuthActionTypes.RequestPasswordChangeFailure, { error })

export const changePasswordAction = (data: DTOs.ResetPasswordDto) =>
  action(AuthActionTypes.ChangePassword, { data })

export const changePasswordSuccessAction = () =>
  action(AuthActionTypes.ChangePasswordSuccess)

export const changePasswordFailureAction = (error: unknown) =>
  action(AuthActionTypes.ChangePasswordFailure, { error })

import { action } from 'typesafe-actions'

import { ContactPersonsActionTypes } from '@app/store/api/contactPersons/contactPersons.constants'
import { ContactPersonDetailDto } from '@shared/dto/contactPerson.dto'

import * as DTOs from '@shared/dto'

export const postContactPersonAction = (
  postContactPersonDto: DTOs.CreateContactPersonDto,
) =>
  action(ContactPersonsActionTypes.PostContactPerson, { postContactPersonDto })

export const postContactPersonSuccessAction = (response: { id: number }) =>
  action(ContactPersonsActionTypes.PostContactPersonSuccess, { response })

export const postContactPersonFailureAction = (error: unknown) =>
  action(ContactPersonsActionTypes.PostContactPersonFailure, { error })

export const cancelPostContactPersonLoadingAction = () =>
  action(ContactPersonsActionTypes.CancelPostContactPerson)

export const patchContactPersonAction = (
  id: number,
  partialContactPersonDto: DTOs.UpdateContactPersonDto,
) =>
  action(ContactPersonsActionTypes.PatchContactPerson, {
    id,
    partialContactPersonDto,
  })

export const patchContactPersonSuccessAction = (
  contactPerson: ContactPersonDetailDto,
) =>
  action(ContactPersonsActionTypes.PatchContactPersonSuccess, { contactPerson })

export const patchContactPersonFailureAction = (error: unknown) =>
  action(ContactPersonsActionTypes.PatchContactPersonFailure, { error })

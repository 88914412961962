// withTranslation

import { call, put, takeLatest } from 'redux-saga/effects'

import * as invitationActions from '@app/store/pages/myTeam/invitation/invitation.actions'

import { api } from '@app/utils/api/api'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { InvitationsActionTypes } from '@app/store/pages/myTeam/invitation/invitation.constants'
import { getErrorMessage } from '@app/utils/errorHandling'
import { getMyTeamListDataAction } from '@app/store/pages/myTeam/myTeamList/myTeamList.actions'

function* postInvitationSaga(
  action: ReturnType<typeof invitationActions.postInvitationAction>,
) {
  try {
    yield call(api.createInvitation, action.payload)

    // t('messages.postInvitation.success')
    yield put(
      addNotificationAction({
        i18nextKey: 'messages.postInvitation.success',
        type: 'success',
      }),
    )

    yield put(invitationActions.postInvitationSuccessAction())

    yield put(getMyTeamListDataAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.alreadyInvited')
      400: 'errors.general.alreadyInvited',
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.postInvitation.notFound')
      404: 'errors.postInvitation.notFound',
      // t('errors.postInvitation.default')
      default: 'errors.postInvitation.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(invitationActions.postInvitationFailureAction(error))
  }
}

function* postResendInvitationSaga(
  action: ReturnType<typeof invitationActions.postResendInvitationAction>,
) {
  try {
    yield call(
      api.postResendInvitation,
      action.payload.operatorId,
      action.payload.userId,
    )

    yield put(
      addNotificationAction({
        // t('messages.postResendInvitation.success')
        i18nextKey: 'messages.postResendInvitation.success',
        type: 'success',
      }),
    )

    yield put(invitationActions.postResendInvitationSuccessAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.postResendInvitation.notFound')
      404: 'errors.postResendInvitation.notFound',
      // t('errors.postResendInvitation.default')
      default: 'errors.postResendInvitation.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(invitationActions.postResendInvitationFailureAction(error))
  }
}

export default function* watchInvitationSaga(): Generator {
  yield takeLatest(InvitationsActionTypes.PostInvitation, postInvitationSaga)

  yield takeLatest(
    InvitationsActionTypes.PostResendInvitation,
    postResendInvitationSaga,
  )
}

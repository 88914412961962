// withTranslation

import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { RequestsReportsActionTypes } from '@app/store/pages/reports/requestsReports/requestsReports.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { selectRequestsReportsFilters } from '@app/store/pages/reports/requestsReports/requestsReports.selectors'

import * as actions from '@app/store/pages/reports/requestsReports/requestsReports.actions'

function* getRequestsReportsSaga() {
  try {
    const filters = selectRequestsReportsFilters(yield select())

    const { data } = yield call(api.getRequestReports, {
      ...filters,
    })

    yield put(actions.getRequestsReportsSuccessAction(data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getRequestsReports.default')
      default: 'errors.getRequestsReports.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getRequestsReportsFailureAction(error))
  }
}

function* setRequestsReportsFiltersSaga(
  action: ReturnType<typeof actions.setRequestsReportsFiltersAction>,
) {
  try {
    const { requestDebounceInMilliseconds } = action.payload

    const { filtersChanged } = yield race({
      filtersChanged: take(
        RequestsReportsActionTypes.SetRequestsReportsFilters,
      ),
      timeout: delay(requestDebounceInMilliseconds),
    })

    if (filtersChanged) {
      return
    }

    yield put(actions.getRequestsReportsAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getRequestsReportsFailureAction(error))
  }
}

export default function* watchRequestsReportsSaga(): Generator {
  yield takeLatest(
    RequestsReportsActionTypes.GetRequestsReports,
    getRequestsReportsSaga,
  )

  yield takeLatest(
    RequestsReportsActionTypes.SetRequestsReportsFilters,
    setRequestsReportsFiltersSaga,
  )
}

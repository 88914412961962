// withTranslation

import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { BookingsReportsActionTypes } from '@app/store/pages/reports/bookingsReports/bookingsReports.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { selectBookingsReportsFilters } from '@app/store/pages/reports/bookingsReports/bookingsReports.selectors'

import * as actions from '@app/store/pages/reports/bookingsReports/bookingsReports.actions'

function* getBookingsReportsSaga() {
  try {
    const filters = selectBookingsReportsFilters(yield select())

    const { data } = yield call(api.getBookingReports, {
      ...filters,
    })

    yield put(actions.getBookingsReportsSuccessAction(data))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getBookingsReports.default')
      default: 'errors.getBookingsReports.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getBookingsReportsFailureAction(error))
  }
}

function* setBookingsReportsFiltersSaga(
  action: ReturnType<typeof actions.setBookingsReportsFiltersAction>,
) {
  try {
    const { requestDebounceInMilliseconds } = action.payload

    const { filtersChanged } = yield race({
      filtersChanged: take(
        BookingsReportsActionTypes.SetBookingsReportsFilters,
      ),
      timeout: delay(requestDebounceInMilliseconds),
    })

    if (filtersChanged) {
      return
    }

    yield put(actions.getBookingsReportsAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getBookingsReportsFailureAction(error))
  }
}

export default function* watchBookingsReportsSaga(): Generator {
  yield takeLatest(
    BookingsReportsActionTypes.GetBookingsReports,
    getBookingsReportsSaga,
  )

  yield takeLatest(
    BookingsReportsActionTypes.SetBookingsReportsFilters,
    setBookingsReportsFiltersSaga,
  )
}

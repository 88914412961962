import { action } from 'typesafe-actions'

import { ScheduleManagementActionTypes } from '@app/store/pages/schedule/scheduleManagement/scheduleManagement.constants'

import * as DTOs from '@shared/dto'

export const postOutageAction = (outage: DTOs.CreateOutageDto) =>
  action(ScheduleManagementActionTypes.PostOutage, outage)

export const postOutageSuccessAction = () =>
  action(ScheduleManagementActionTypes.PostOutageSuccess)

export const postOutageFailureAction = (error: unknown) =>
  action(ScheduleManagementActionTypes.PostOutageFailure, error)

export const patchOutageAction = (
  id: number,
  partialOutage: DTOs.UpdateOutageDto,
) => action(ScheduleManagementActionTypes.PatchOutage, { id, partialOutage })

export const patchOutageSuccessAction = () =>
  action(ScheduleManagementActionTypes.PatchOutageSuccess)

export const patchOutageFailureAction = (error: unknown) =>
  action(ScheduleManagementActionTypes.PatchOutageFailure, error)

export const deleteOutageAction = (id: number) =>
  action(ScheduleManagementActionTypes.DeleteOutage, id)

export const deleteOutageSuccessAction = () =>
  action(ScheduleManagementActionTypes.DeleteOutageSuccess)

export const deleteOutageFailureAction = (error: unknown) =>
  action(ScheduleManagementActionTypes.DeleteOutageFailure, error)

export const patchMarketplaceExtensionAction = (
  id: number,
  data: DTOs.UpdateMarketplaceExtensionDto,
) =>
  action(ScheduleManagementActionTypes.PatchMarketplaceExtension, { id, data })

export const patchMarketplaceExtensionSuccessAction = () =>
  action(ScheduleManagementActionTypes.PatchMarketplaceExtensionSuccess)

export const patchMarketplaceExtensionFailureAction = (error: unknown) =>
  action(ScheduleManagementActionTypes.PatchMarketplaceExtensionFailure, error)

export const deleteMarketplaceExtensionAction = (id: number) =>
  action(ScheduleManagementActionTypes.DeleteMarketplaceExtension, id)

export const deleteMarketplaceExtensionSuccessAction = () =>
  action(ScheduleManagementActionTypes.DeleteMarketplaceExtensionSuccess)

export const deleteMarketplaceExtensionFailureAction = (error: unknown) =>
  action(ScheduleManagementActionTypes.DeleteMarketplaceExtensionFailure, error)

export const clearScheduleManagementStore = () =>
  action(ScheduleManagementActionTypes.ClearScheduleManagementStore)

import { action } from 'typesafe-actions'

import { CompanySettingsActionTypes } from '@app/store/pages/settings/companySettings/companySettings.constants'

import * as DTOs from '@shared/dto'

export const getCompanyAction = (id: number) =>
  action(CompanySettingsActionTypes.GetCompany, { id })

export const getCompanySuccessAction = (company: DTOs.CompanyDto) =>
  action(CompanySettingsActionTypes.GetCompanySuccess, { company })

export const getCompanyFailureAction = (error: unknown) =>
  action(CompanySettingsActionTypes.GetCompanyFailure, { error })

export const patchCompanyAction = (
  id: number,
  patchCompanyDto: DTOs.UpdateCompanyDto,
) => action(CompanySettingsActionTypes.PatchCompany, { id, patchCompanyDto })

export const patchCompanySuccessAction = (company: DTOs.CompanyDto) =>
  action(CompanySettingsActionTypes.PatchCompanySuccess, { company })

export const patchCompanyFailureAction = (error: unknown) =>
  action(CompanySettingsActionTypes.PatchCompanyFailure, { error })

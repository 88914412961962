import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import {
  FALLBACK_LANGUAGE,
  DEFAULT_TRANSLATION_NAMESPACE,
} from '@app/constants'

const GATSBY_APP_BASE_URL = process.env.GATSBY_APP_BASE_URL

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    ns: [DEFAULT_TRANSLATION_NAMESPACE],
    defaultNS: DEFAULT_TRANSLATION_NAMESPACE,
    fallbackLng: FALLBACK_LANGUAGE,
    backend: {
      loadPath: `${GATSBY_APP_BASE_URL}/translations/{{lng}}/{{ns}}.json`,
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n

// withTranslation

import { AxiosResponse } from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { MyTeamListActionTypes } from '@app/store/pages/myTeam/myTeamList/myTeamList.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'

import * as actions from '@app/store/pages/myTeam/myTeamList/myTeamList.actions'

import * as DTOs from '@shared/dto'

function* getMyTeamListDataSaga() {
  try {
    const { data }: AxiosResponse<DTOs.UserDetailDto[]> = yield call(
      api.getUsers,
    )

    yield put(actions.getMyTeamListSuccessAction(data))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getMyTeamList.default')
      default: 'errors.getMyTeamList.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getMyTeamListFailureAction(error))
  }
}

function* getUserDetailSaga(
  action: ReturnType<typeof actions.getUserDataAction>,
) {
  try {
    const { data } = yield call(api.getUser, action.payload)

    yield put(actions.getUserSuccessAction(data))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.general.forbidden')
      403: 'errors.general.forbidden',
      // t('errors.getUser.notFound')
      404: 'errors.getUser.notFound',
      // t('errors.getUser.default')
      default: 'errors.getUser.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getUserFailureAction(error))
  }
}

function* patchUserSaga(
  action: ReturnType<typeof actions.patchUserDataAction>,
) {
  try {
    yield call(api.updateUser, action.payload.id, action.payload.body)

    yield put(actions.patchUserDataSuccessAction())

    yield put(actions.getMyTeamListDataAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.general.forbidden')
      403: 'errors.general.forbidden',
      // t('errors.patchUser.notFound')
      404: 'errors.patchUser.notFound',
      // t('errors.patchUser.invalidData')
      422: 'errors.patchUser.invalidData',
      // t('errors.patchUser.default')
      default: 'errors.patchUser.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.patchUserDataFailureAction(error))
  }
}

function* setMyTeamListGetParametersSaga(
  action: ReturnType<typeof actions.startSetMyTeamListSortAction>,
) {
  try {
    yield put(actions.setMyTeamListParametersAction(action.payload))
    yield put(actions.getMyTeamListDataAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getMyTeamListFailureAction(error))
  }
}

export default function* watchMyTeamListSaga(): Generator {
  yield takeLatest(
    MyTeamListActionTypes.GetMyTeamListData,
    getMyTeamListDataSaga,
  )

  yield takeLatest(MyTeamListActionTypes.GetUserData, getUserDetailSaga)

  yield takeLatest(MyTeamListActionTypes.PatchUserData, patchUserSaga)

  yield takeLatest(
    MyTeamListActionTypes.StartSetMyTeamListSort,
    setMyTeamListGetParametersSaga,
  )
}

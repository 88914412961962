import { action } from 'typesafe-actions'

import { RequestsReportsActionTypes } from '@app/store/pages/reports/requestsReports/requestsReports.constants'
import { RequestReportsDto } from '@shared/dto/reports.dto'

import * as DTOs from '@shared/dto'

export const getRequestsReportsAction = () =>
  action(RequestsReportsActionTypes.GetRequestsReports)

export const getRequestsReportsSuccessAction = (response: RequestReportsDto) =>
  action(RequestsReportsActionTypes.GetRequestsReportsSuccess, response)

export const getRequestsReportsFailureAction = (error: unknown) =>
  action(RequestsReportsActionTypes.GetRequestsReportsFailure, { error })

export const setRequestsReportsFiltersAction = (
  filters: Partial<DTOs.ReportsSearchParams>,
  requestDebounceInMilliseconds = 0,
) =>
  action(RequestsReportsActionTypes.SetRequestsReportsFilters, {
    filters,
    requestDebounceInMilliseconds,
  })

export const resetRequestsReportsFiltersAction = () =>
  action(RequestsReportsActionTypes.ResetRequestsReportsFilters)

import { action } from 'typesafe-actions'

import { ClientsActionTypes } from '@app/store/api/clients/clients.constants'

import * as DTOs from '@shared/dto'

export const postClientAction = (postClientDto: DTOs.CreateClientDto) =>
  action(ClientsActionTypes.PostClient, { postClientDto })

export const postClientSuccessAction = (response: DTOs.ClientDetailDto) =>
  action(ClientsActionTypes.PostClientSuccess, { response })

export const postClientFailureAction = (error: unknown) =>
  action(ClientsActionTypes.PostClientFailure, { error })

import { action } from 'typesafe-actions'

import { CustomRoutesActionTypes } from '@app/store/api/customRoutes/customRoutes.constants'
import { CustomRoutesSortProperties } from '@app/store/api/customRoutes/customRoutes.types'

import { GetCustomRoutesFilters } from '@app/utils/api/types'

import * as DTOs from '@shared/dto'

export const getCustomRoutesDataFirstPageAction = () =>
  action(CustomRoutesActionTypes.GetCustomRoutesDataFirstPage)

export const getCustomRoutesDataNextPageAction = () =>
  action(CustomRoutesActionTypes.GetCustomRoutesDataNextPage)

export const getCustomRoutesSuccessAction = (
  response: DTOs.PaginatedList<DTOs.CustomRouteDetailDto>,
) => action(CustomRoutesActionTypes.GetCustomRoutesSuccess, response)

export const getCustomRoutesFailureAction = (error: unknown) =>
  action(CustomRoutesActionTypes.GetCustomRoutesFailure, error)

export const setCustomRoutesSortAction = (sort: CustomRoutesSortProperties) =>
  action(CustomRoutesActionTypes.SetCustomRoutesSort, sort)

export const setCustomRoutesFiltersAction = (
  filters: Partial<GetCustomRoutesFilters>,
  requestDebounceInMilliseconds = 0,
) =>
  action(CustomRoutesActionTypes.SetCustomRoutesFilters, {
    filters,
    requestDebounceInMilliseconds,
  })

export const resetCustomRoutesFiltersAction = () =>
  action(CustomRoutesActionTypes.ResetCustomRoutesFilters)

export const postCustomRouteAction = (
  postCustomRouteDto: DTOs.CreateCustomRouteDto,
) => action(CustomRoutesActionTypes.PostCustomRoute, postCustomRouteDto)

export const postCustomRouteSuccessAction = (response: { id: number }) =>
  action(CustomRoutesActionTypes.PostCustomRouteSuccess, response)

export const postCustomRouteFailureAction = (error: unknown) =>
  action(CustomRoutesActionTypes.PostCustomRouteFailure, error)

export const cancelPostCustomRouteLoadingAction = () =>
  action(CustomRoutesActionTypes.CancelPostCustomRouteLoading)

export const cancelDeleteCustomRouteLoadingAction = () =>
  action(CustomRoutesActionTypes.CancelDeleteCustomRouteLoading)

export const patchCustomRouteAction = (
  id: number,
  patchCustomRouteDto: DTOs.UpdateCustomRouteDto,
) =>
  action(CustomRoutesActionTypes.PatchCustomRoute, {
    data: patchCustomRouteDto,
    id,
  })

export const patchCustomRouteSuccessAction = (
  updatedCustomRoute: DTOs.CustomRouteDetailDto,
) => action(CustomRoutesActionTypes.PatchCustomRouteSuccess, updatedCustomRoute)

export const patchCustomRouteFailureAction = (error: unknown) =>
  action(CustomRoutesActionTypes.PatchCustomRouteFailure, error)

export const deleteCustomRouteAction = (id: number) =>
  action(CustomRoutesActionTypes.DeleteCustomRoute, id)

export const deleteCustomRouteSuccessAction = () =>
  action(CustomRoutesActionTypes.DeleteCustomRouteSuccess)

export const deleteCustomRouteFailureAction = (error: unknown) =>
  action(CustomRoutesActionTypes.DeleteCustomRouteFailure, error)

export const reloadCustomRouteListAction = () =>
  action(CustomRoutesActionTypes.ReloadCustomRoutes)

export const setCustomRoutesDataAction = (
  data: DTOs.PaginatedList<DTOs.CustomRouteDetailDto>,
) => action(CustomRoutesActionTypes.SetCustomRoutesData, data)

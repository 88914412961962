// withTranslation

import { call, put, select, takeLatest } from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { MyTeamManagementActionTypes } from '@app/store/pages/myTeam/myTeamManagement/myTeamManagement.constants'
import { getErrorMessage } from '@app/utils/errorHandling'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'

import * as actions from '@app/store/pages/myTeam/myTeamManagement/myTeamManagement.actions'
import * as myTeamListActions from '@app/store/pages/myTeam/myTeamList/myTeamList.actions'

function* putUserStatusSaga(
  action: ReturnType<typeof actions.putUserStatusAction>,
) {
  try {
    const operator = selectSelectedOperator(yield select())

    if (!operator) {
      throw new Error('Operator is required')
    }

    yield call(
      api.updateUserStatus,
      action.payload.userId,
      operator.id,
      action.payload.status,
    )

    yield put(actions.putUserStatusSuccessAction())
    yield put(myTeamListActions.getMyTeamListDataAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.putUserStatus.invalidStatus')
      400: 'errors.putUserStatus.invalidStatus',
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.putUserStatus.forbidden')
      403: 'errors.putUserStatus.forbidden',
      // t('errors.putUserStatus.notFound')
      404: 'errors.putUserStatus.notFound',
      // t('errors.putUserStatus.default')
      default: 'errors.putUserStatus.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.putUserStatusFailureAction(error))
  }
}

export default function* watchMyTeamManagementSaga(): Generator {
  yield takeLatest(MyTeamManagementActionTypes.PutUserStatus, putUserStatusSaga)
}

// withTranslation

import axios, { AxiosResponse } from 'axios'
import produce from 'immer'

import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { CustomRoutesActionTypes } from '@app/store/api/customRoutes/customRoutes.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import * as actions from '@app/store/api/customRoutes/customRoutes.actions'
import * as dialogActions from '@app/store/ui/confirmationDialog/confirmationDialog.actions'
import { ConfirmationDialogActionTypes } from '@app/store/ui/confirmationDialog/confirmationDialog.constants'
import { getErrorMessage } from '@app/utils/errorHandling'

import { PostCustomRouteErrorResponse } from '@app/utils/api/types'

import { CustomRouteDetailDto } from '@shared/dto/customRoutes.dto'

import {
  selectCustomRoutesFilters,
  selectCustomRoutesLimit,
  selectCustomRoutesOrderBy,
  selectCustomRoutesOrderDirection,
  selectCustomRoutesPage,
} from '@app/store/api/customRoutes/customRoutes.selectors'

import * as DTOs from '@shared/dto'

function* getCustomRoutesDataSaga(page: number) {
  try {
    const limit = selectCustomRoutesLimit(yield select())
    const orderDirection = selectCustomRoutesOrderDirection(yield select())
    const orderBy = selectCustomRoutesOrderBy(yield select())
    const { aircraft, ...filters } = selectCustomRoutesFilters(yield select())

    const params: DTOs.CustomRouteSearchParams = {
      ...filters,
      orderBy,
      orderDirection: orderDirection ?? 'DESC',
      limit,
      page,
      aircraft_registration_code: aircraft?.registration_code,
    }

    const {
      data,
    }: AxiosResponse<DTOs.PaginatedList<DTOs.CustomRouteDetailDto>> =
      yield call(api.listCustomRoutes, params)

    yield put(actions.getCustomRoutesSuccessAction(data))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getCustomRoutes.default')
      default: 'errors.getCustomRoutes.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getCustomRoutesFailureAction(error))
  }
}

function* getInitialCustomRoutesDataSaga() {
  yield getCustomRoutesDataSaga(1)
}

function* getMoreCustomRoutesDataSaga() {
  const page = selectCustomRoutesPage(yield select())

  yield getCustomRoutesDataSaga(page + 1)
}

function* setCustomRoutesFiltersSaga(
  action: ReturnType<typeof actions.setCustomRoutesFiltersAction>,
) {
  try {
    const { requestDebounceInMilliseconds } = action.payload

    const { filtersChanged } = yield race({
      filtersChanged: take(CustomRoutesActionTypes.SetCustomRoutesFilters),
      timeout: delay(requestDebounceInMilliseconds),
    })

    if (filtersChanged) {
      return
    }

    yield put(actions.getCustomRoutesDataFirstPageAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getCustomRoutesFailureAction(error))
  }
}

function* setCustomRoutesSortSaga() {
  try {
    yield put(actions.getCustomRoutesDataFirstPageAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getCustomRoutesFailureAction(error))
  }
}

function* postCustomRouteSaga(
  action: ReturnType<typeof actions.postCustomRouteAction>,
) {
  try {
    const { data }: AxiosResponse<{ id: number }> = yield call(
      api.createCustomRoute,
      {
        ...action.payload,
      },
    )

    yield put(
      addNotificationAction({
        // t('messages.postCustomRoute.success')
        i18nextKey: 'messages.postCustomRoute.success',
        type: 'success',
      }),
    )

    yield put(actions.reloadCustomRouteListAction())
    yield put(actions.postCustomRouteSuccessAction(data))
  } catch (error) {
    console.error(error)

    const getIsPostCustomRouteErrorResponse = (
      error: unknown,
    ): error is PostCustomRouteErrorResponse =>
      !!(error as PostCustomRouteErrorResponse).existing_custom_route_id

    if (
      axios.isAxiosError(error) &&
      error.response?.status === 409 &&
      getIsPostCustomRouteErrorResponse(error.response?.data)
    ) {
      yield put(
        dialogActions.openConfirmationDialogAction({
          // t('confirmation.postCustomRouteConflict')
          i18nextKey: 'confirmation.postCustomRouteConflict',
        }),
      )

      const { cancel } = yield race({
        submit: take(ConfirmationDialogActionTypes.SubmitConfirmationDialog),
        cancel: take(ConfirmationDialogActionTypes.CloseConfirmationDialog),
      })

      if (cancel) {
        yield put(actions.cancelPostCustomRouteLoadingAction())
        return
      }

      try {
        yield put(
          actions.patchCustomRouteAction(
            error.response.data.existing_custom_route_id,
            action.payload,
          ),
        )

        yield put(actions.cancelPostCustomRouteLoadingAction())
        yield put(dialogActions.closeConfirmationDialogAction())

        return
      } catch (error) {
        yield put(
          addNotificationAction({
            // t('errors.postCustomRoute.default')
            i18nextKey: 'errors.postCustomRoute.default',
            type: 'error',
          }),
        )

        yield put(actions.postCustomRouteFailureAction(error))
      }

      return
    }

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.postCustomRoute.notFound')
      404: 'errors.postCustomRoute.notFound',
      // t('errors.postCustomRoute.conflict')
      409: 'errors.postCustomRoute.conflict',
      // t('errors.postCustomRoute.default')
      default: 'errors.postCustomRoute.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.postCustomRouteFailureAction(error))
  }
}

function* patchCustomRouteSaga(
  action: ReturnType<typeof actions.patchCustomRouteAction>,
) {
  try {
    const { data }: AxiosResponse<CustomRouteDetailDto> = yield call(
      api.updateCustomRoute,
      action.payload.id,
      { ...action.payload.data },
    )

    yield put(
      addNotificationAction({
        // t('messages.patchCustomRoute.success')
        i18nextKey: 'messages.patchCustomRoute.success',
        type: 'success',
      }),
    )

    yield put(actions.reloadCustomRouteListAction())
    yield put(actions.patchCustomRouteSuccessAction(data))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.patchCustomRoute.notFound')
      404: 'errors.patchCustomRoute.notFound',
      // t('errors.patchCustomRoute.default')
      default: 'errors.patchCustomRoute.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.patchCustomRouteFailureAction(error))
  }
}

function* deleteCustomRouteSaga(
  action: ReturnType<typeof actions.deleteCustomRouteAction>,
) {
  try {
    yield put(
      dialogActions.openConfirmationDialogAction({
        // t('confirmation.deleteCustomRouteConfirmation')
        i18nextKey: 'confirmation.deleteCustomRouteConfirmation',
      }),
    )

    const { cancel } = yield race({
      submit: take(ConfirmationDialogActionTypes.SubmitConfirmationDialog),
      cancel: take(ConfirmationDialogActionTypes.CloseConfirmationDialog),
    })

    if (cancel) {
      yield put(actions.cancelDeleteCustomRouteLoadingAction())

      return
    }

    yield call(api.deleteCustomRoute, action.payload)

    yield put(dialogActions.closeConfirmationDialogAction())

    yield put(
      addNotificationAction({
        // t('messages.deleteCustomRoute.success')
        i18nextKey: 'messages.deleteCustomRoute.success',
        type: 'success',
      }),
    )

    yield put(actions.reloadCustomRouteListAction())
    yield put(actions.deleteCustomRouteSuccessAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.deleteCustomRoute.notFound')
      404: 'errors.deleteCustomRoute.notFound',
      // t('errors.deleteCustomRoute.default')
      default: 'errors.deleteCustomRoute.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.deleteCustomRouteFailureAction(error))
  }
}

function* reloadCustomRoutesListSaga() {
  const page = selectCustomRoutesPage(yield select())
  const limit = selectCustomRoutesLimit(yield select())
  const orderDirection = selectCustomRoutesOrderDirection(yield select())
  const filters = selectCustomRoutesFilters(yield select())
  const orderBy = selectCustomRoutesOrderBy(yield select())
  const operator = selectSelectedOperator(yield select())

  if (!operator) {
    throw new Error('Operator must be selected')
  }

  const params: DTOs.CustomRouteSearchParams = {
    ...filters,
    orderBy,
    orderDirection: orderDirection ?? 'DESC',
    limit,
    page: 1,
  }

  try {
    const {
      data,
    }: AxiosResponse<DTOs.PaginatedList<DTOs.CustomRouteDetailDto>> =
      yield call(
        api.listCustomRoutes,
        produce(params, (draft) => {
          if (draft.limit && page) {
            draft.limit = draft.limit * page
          }
        }),
      )

    yield put(actions.setCustomRoutesDataAction(data))
  } catch (error) {
    console.error(error)

    yield put(actions.getCustomRoutesFailureAction(error))
  }
}

export default function* watchCustomRoutesSaga(): Generator {
  yield takeLatest(
    CustomRoutesActionTypes.GetCustomRoutesDataFirstPage,
    getInitialCustomRoutesDataSaga,
  )

  yield takeLatest(
    CustomRoutesActionTypes.GetCustomRoutesDataNextPage,
    getMoreCustomRoutesDataSaga,
  )

  yield takeLatest(
    CustomRoutesActionTypes.SetCustomRoutesSort,
    setCustomRoutesSortSaga,
  )

  yield takeLatest(
    CustomRoutesActionTypes.SetCustomRoutesFilters,
    setCustomRoutesFiltersSaga,
  )

  yield takeLatest(CustomRoutesActionTypes.PostCustomRoute, postCustomRouteSaga)

  yield takeLatest(
    CustomRoutesActionTypes.PatchCustomRoute,
    patchCustomRouteSaga,
  )

  yield takeLatest(
    CustomRoutesActionTypes.DeleteCustomRoute,
    deleteCustomRouteSaga,
  )

  yield takeLatest(
    CustomRoutesActionTypes.ReloadCustomRoutes,
    reloadCustomRoutesListSaga,
  )
}

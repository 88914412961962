// withTranslation

import { call, put, race, take, takeLatest } from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'

import { ScheduleManagementActionTypes } from '@app/store/pages/schedule/scheduleManagement/scheduleManagement.constants'
import { ConfirmationDialogActionTypes } from '@app/store/ui/confirmationDialog/confirmationDialog.constants'
import * as actions from '@app/store/pages/schedule/scheduleManagement/scheduleManagement.actions'
import * as scheduleListActions from '@app/store/pages/schedule/scheduleList/schedule.actions'
import * as dialogActions from '@app/store/ui/confirmationDialog/confirmationDialog.actions'

function* postOutageSaga(action: ReturnType<typeof actions.postOutageAction>) {
  try {
    yield call(api.createOutage, action.payload)

    yield put(
      addNotificationAction({
        // t('messages.postOutage.success')
        i18nextKey: 'messages.postOutage.success',
        type: 'success',
      }),
    )

    yield put(actions.postOutageSuccessAction())
    yield put(scheduleListActions.reloadScheduleListAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.postOutage.404')
      404: 'errors.postOutage.404',
      // t('errors.postOutage.409')
      409: 'errors.postOutage.409',
      // t('errors.postOutage.default')
      default: 'errors.postOutage.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.postOutageFailureAction(error))
  }
}

function* patchOutageSaga(
  action: ReturnType<typeof actions.patchOutageAction>,
) {
  try {
    yield call(
      api.updateOutage,
      action.payload.id,
      action.payload.partialOutage,
    )

    yield put(
      addNotificationAction({
        // t('messages.patchOutage.success')
        i18nextKey: 'messages.patchOutage.success',
        type: 'success',
      }),
    )

    yield put(scheduleListActions.reloadScheduleListAction())
    yield put(actions.patchOutageSuccessAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.patchOutage.404')
      404: 'errors.patchOutage.404',
      // t('errors.patchOutage.409')
      409: 'errors.patchOutage.409',
      // t('errors.patchOutage.default')
      default: 'errors.patchOutage.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.patchOutageFailureAction(error))
  }
}

function* deleteOutageSaga(
  action: ReturnType<typeof actions.deleteOutageAction>,
) {
  try {
    yield put(
      dialogActions.openConfirmationDialogAction({
        // t('confirmation.deleteOutageConfirmation')
        i18nextKey: 'confirmation.deleteOutageConfirmation',
      }),
    )

    const { cancel } = yield race({
      submit: take(ConfirmationDialogActionTypes.SubmitConfirmationDialog),
      cancel: take(ConfirmationDialogActionTypes.CloseConfirmationDialog),
    })

    if (cancel) {
      return
    }

    yield call(api.deleteOutage, action.payload)

    yield put(
      addNotificationAction({
        // t('messages.deleteOutage.success')
        i18nextKey: 'messages.deleteOutage.success',
        type: 'success',
      }),
    )

    yield put(dialogActions.closeConfirmationDialogAction())
    yield put(scheduleListActions.reloadScheduleListAction())
    yield put(actions.deleteOutageSuccessAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.deleteOutage.404')
      404: 'errors.deleteOutage.404',
      // t('errors.deleteOutage.default')
      default: 'errors.deleteOutage.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.deleteOutageFailureAction(error))
  }
}

function* patchMarketplaceExtensionSaga(
  action: ReturnType<typeof actions.patchMarketplaceExtensionAction>,
) {
  try {
    yield call(
      api.updateMarketplaceExtension,
      action.payload.id,
      action.payload.data,
    )

    yield put(
      addNotificationAction({
        // t('messages.patchMarketplaceExtension.success')
        i18nextKey: 'messages.patchMarketplaceExtension.success',
        type: 'success',
      }),
    )

    yield put(scheduleListActions.reloadScheduleListAction())
    yield put(actions.patchMarketplaceExtensionSuccessAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.patchMarketplaceExtension.404')
      404: 'errors.patchMarketplaceExtension.404',
      // t('errors.patchMarketplaceExtension.409')
      409: 'errors.patchMarketplaceExtension.409',
      // t('errors.patchMarketplaceExtension.default')
      default: 'errors.patchMarketplaceExtension.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.patchMarketplaceExtensionFailureAction(error))
  }
}

function* deleteMarketplaceExtensionSaga(
  action: ReturnType<typeof actions.deleteMarketplaceExtensionAction>,
) {
  try {
    yield put(
      dialogActions.openConfirmationDialogAction({
        // t('confirmation.deleteMarketplaceExtensionConfirmation')
        i18nextKey: 'confirmation.deleteMarketplaceExtensionConfirmation',
      }),
    )

    const { cancel } = yield race({
      submit: take(ConfirmationDialogActionTypes.SubmitConfirmationDialog),
      cancel: take(ConfirmationDialogActionTypes.CloseConfirmationDialog),
    })

    if (cancel) {
      return
    }

    yield call(api.deleteMarketplaceExtension, action.payload)

    yield put(
      addNotificationAction({
        // t('messages.deleteMarketplaceExtension.success')
        i18nextKey: 'messages.deleteMarketplaceExtension.success',
        type: 'success',
      }),
    )

    yield put(dialogActions.closeConfirmationDialogAction())
    yield put(scheduleListActions.reloadScheduleListAction())
    yield put(actions.deleteMarketplaceExtensionSuccessAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.deleteMarketplaceExtension.404')
      404: 'errors.deleteMarketplaceExtension.404',
      // t('errors.deleteMarketplaceExtension.default')
      default: 'errors.deleteMarketplaceExtension.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.deleteMarketplaceExtensionFailureAction(error))
  }
}

export default function* watchScheduleManagementSaga(): Generator {
  yield takeLatest(ScheduleManagementActionTypes.PostOutage, postOutageSaga)

  yield takeLatest(ScheduleManagementActionTypes.PatchOutage, patchOutageSaga)

  yield takeLatest(ScheduleManagementActionTypes.DeleteOutage, deleteOutageSaga)

  yield takeLatest(
    ScheduleManagementActionTypes.PatchMarketplaceExtension,
    patchMarketplaceExtensionSaga,
  )

  yield takeLatest(
    ScheduleManagementActionTypes.DeleteMarketplaceExtension,
    deleteMarketplaceExtensionSaga,
  )
}

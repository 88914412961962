// withTranslation

import { AxiosResponse } from 'axios'
import produce from 'immer'

import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { ContactPersonsActionTypes } from '@app/store/pages/clientDetail/contactPersons/contactPersons.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { ConfirmationDialogActionTypes } from '@app/store/ui/confirmationDialog/confirmationDialog.constants'

import * as actions from '@app/store/pages/clientDetail/contactPersons/contactPersons.actions'
import * as dialogActions from '@app/store/ui/confirmationDialog/confirmationDialog.actions'

import {
  selectContactPersonsFilters,
  selectContactPersonsLimit,
  selectContactPersonsOrderBy,
  selectContactPersonsOrderDirection,
  selectContactPersonsPage,
} from '@app/store/pages/clientDetail/contactPersons/contactPersons.selectors'

import * as DTOs from '@shared/dto'

function* getContactPersonsDataSaga(page: number) {
  try {
    const limit = selectContactPersonsLimit(yield select())
    const orderDirection = selectContactPersonsOrderDirection(yield select())
    const filters = selectContactPersonsFilters(yield select())
    const orderBy = selectContactPersonsOrderBy(yield select())

    const params: DTOs.GetCPQuery = {
      ...filters,
      orderBy,
      orderDirection: orderDirection ?? 'DESC',
      limit,
      page,
    }

    const {
      data,
    }: AxiosResponse<DTOs.PaginatedList<DTOs.ContactPersonDetailDto>> =
      yield call(api.listContactPersons, params)

    yield put(actions.getContactPersonsSuccessAction(data))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getContactPersons.default')
      default: 'errors.getContactPersons.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getContactPersonsFailureAction(error))
  }
}

function* getInitialContactPersonsDataSaga() {
  yield getContactPersonsDataSaga(1)
}

function* getMoreContactPersonsDataSaga() {
  const page = selectContactPersonsPage(yield select())

  yield getContactPersonsDataSaga(page + 1)
}

function* setContactPersonsFiltersSaga(
  action: ReturnType<typeof actions.setContactPersonsFiltersAction>,
) {
  try {
    const { requestDebounceInMilliseconds } = action.payload

    const { filtersChanged } = yield race({
      filtersChanged: take(ContactPersonsActionTypes.SetContactPersonsFilters),
      timeout: delay(requestDebounceInMilliseconds),
    })

    if (filtersChanged) {
      return
    }

    yield put(actions.getContactPersonsDataFirstPageAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getContactPersonsFailureAction(error))
  }
}

function* setContactPersonsSortSaga() {
  try {
    yield put(actions.getContactPersonsDataFirstPageAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getContactPersonsFailureAction(error))
  }
}

function* reloadContactPersonsSaga() {
  const limit = selectContactPersonsLimit(yield select())
  const page = selectContactPersonsPage(yield select())
  const orderDirection = selectContactPersonsOrderDirection(yield select())
  const filters = selectContactPersonsFilters(yield select())
  const orderBy = selectContactPersonsOrderBy(yield select())

  const params: DTOs.GetCPQuery = {
    ...filters,
    orderBy,
    orderDirection: orderDirection ?? 'DESC',
    limit: limit * page,
    page: 1,
  }

  try {
    const {
      data,
    }: AxiosResponse<DTOs.PaginatedList<DTOs.ContactPersonDetailDto>> =
      yield call(
        api.listContactPersons,
        produce(params, (draft) => {
          if (draft.limit && draft.page) {
            draft.limit = draft.limit * draft.page
          }
        }),
      )

    yield put(actions.reloadContactPersonsSuccessAction(data))
  } catch (error) {
    console.error(error)

    yield put(actions.getContactPersonsFailureAction(error))
  }
}

function* deleteContactPersonSaga(
  action: ReturnType<typeof actions.deleteContactPersonAction>,
) {
  try {
    yield put(
      dialogActions.openConfirmationDialogAction({
        // t('confirmations.deleteContactPersonConfirmation')
        i18nextKey: 'confirmations.deleteContactPersonConfirmation',
      }),
    )

    const { cancel } = yield race({
      submit: take(ConfirmationDialogActionTypes.SubmitConfirmationDialog),
      cancel: take(ConfirmationDialogActionTypes.CloseConfirmationDialog),
    })

    if (cancel) {
      yield put(actions.cancelContactPersonDeleteAction())
      return
    }

    yield call(api.deleteContactPerson, action.payload.id)

    yield put(
      addNotificationAction({
        // t('messages.deleteContactPerson.success')
        i18nextKey: 'messages.deleteContactPerson.success',
        type: 'success',
      }),
    )

    yield put(dialogActions.closeConfirmationDialogAction())
    yield put(actions.deleteContactPersonSuccessAction())
    yield put(actions.reloadContactPersonsAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.deleteContactPerson.notFound')
      404: 'errors.deleteContactPerson.notFound',
      // t('errors.deleteContactPerson.default')
      default: 'errors.deleteContactPerson.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.deleteContactPersonFailureAction(error))
  }
}

export default function* watchContactPersonsSaga(): Generator {
  yield takeLatest(
    ContactPersonsActionTypes.GetContactPersonsDataFirstPage,
    getInitialContactPersonsDataSaga,
  )

  yield takeLatest(
    ContactPersonsActionTypes.GetContactPersonsDataNextPage,
    getMoreContactPersonsDataSaga,
  )

  yield takeLatest(
    ContactPersonsActionTypes.ReloadContactPersons,
    reloadContactPersonsSaga,
  )

  yield takeLatest(
    ContactPersonsActionTypes.SetContactPersonsFilters,
    setContactPersonsFiltersSaga,
  )

  yield takeLatest(
    ContactPersonsActionTypes.SetContactPersonsSort,
    setContactPersonsSortSaga,
  )

  yield takeLatest(
    ContactPersonsActionTypes.DeleteContactPerson,
    deleteContactPersonSaga,
  )
}

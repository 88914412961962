// withTranslation

import { AxiosResponse } from 'axios'
import { call, put, select, SelectEffect, takeLatest } from 'redux-saga/effects'
import produce from 'immer'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { ScheduleActionTypes } from '@app/store/pages/schedule/scheduleList/schedule.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { RootState } from '@app/store'
import * as actions from '@app/store/pages/schedule/scheduleList/schedule.actions'
import { getAllScheduleData } from '@app/store/pages/schedule/scheduleList/schedule.actions'

import {
  selectScheduleData,
  selectScheduleFilters,
  selectScheduleLimit,
  selectScheduleOrderBy,
  selectScheduleOrderDirection,
  selectSchedulePage,
  selectScheduleTotal,
} from '@app/store/pages/schedule/scheduleList/schedule.selectors'

import * as DTOs from '@shared/dto'

function* getScheduleDataSaga(page: number) {
  try {
    const limit = selectScheduleLimit(yield select())
    const orderDirection = selectScheduleOrderDirection(yield select())
    const filters = selectScheduleFilters(yield select())
    const orderBy = selectScheduleOrderBy(yield select())

    const {
      arrival_date_start,
      departure_date_end,
      departure_date_start,
      arrival_date_end,
      ...restFilters
    } = filters

    const params: DTOs.ScheduleSearchParams = {
      ...restFilters,
      arrival_date_start: arrival_date_start
        ? new Date(arrival_date_start)
        : undefined,
      departure_date_end: departure_date_end
        ? new Date(departure_date_end)
        : undefined,
      departure_date_start: departure_date_start
        ? new Date(departure_date_start)
        : undefined,
      arrival_date_end: arrival_date_end
        ? new Date(arrival_date_end)
        : undefined,
      orderBy,
      orderDirection: orderDirection ?? 'DESC',
      limit,
      page,
    }

    const { data }: AxiosResponse<DTOs.PaginatedScheduleListDto> = yield call(
      api.getScheduleList,
      params,
    )

    yield put(actions.getScheduleSuccessAction(data))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getSchedule.default')
      default: 'errors.getSchedule.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getScheduleFailureAction(error))
  }
}

function* getInitialScheduleDataSaga() {
  yield getScheduleDataSaga(1)
}

function* getAllScheduleDataSaga(
  action?: ReturnType<typeof getAllScheduleData>,
): Generator<SelectEffect | Generator, void, RootState> {
  if (action) {
    yield getInitialScheduleDataSaga()
  }

  const data = selectScheduleData(yield select())
  const total = selectScheduleTotal(yield select())

  if (data && total && data.length < total) {
    yield getMoreScheduleDataSaga()
    yield getAllScheduleDataSaga()
  }
}

function* getMoreScheduleDataSaga() {
  const page = selectSchedulePage(yield select())

  yield getScheduleDataSaga(page + 1)
}

function* setScheduleFiltersSaga(
  action: ReturnType<typeof actions.startSetScheduleFiltersAction>,
) {
  try {
    yield put(actions.setScheduleParametersAction(action.payload))

    if (action.payload.fetchAll) {
      yield put(actions.getAllScheduleData())
    } else {
      yield put(actions.getScheduleDataFirstPageAction())
    }
  } catch (error) {
    console.error(error)

    yield put(actions.getScheduleFailureAction(error))
  }
}

function* setScheduleSortSaga(
  action: ReturnType<typeof actions.startSetScheduleFiltersAction>,
) {
  try {
    yield put(actions.setScheduleParametersAction(action.payload))

    yield put(actions.getScheduleDataFirstPageAction())
  } catch (error) {
    console.error(error)

    yield put(actions.getScheduleFailureAction(error))
  }
}

function* reloadScheduleListSaga() {
  const page = selectSchedulePage(yield select())
  const limit = selectScheduleLimit(yield select())
  const orderDirection = selectScheduleOrderDirection(yield select())
  const filters = selectScheduleFilters(yield select())
  const orderBy = selectScheduleOrderBy(yield select())

  const {
    arrival_date_start,
    departure_date_end,
    departure_date_start,
    arrival_date_end,
    ...restFilters
  } = filters

  const params: DTOs.ScheduleSearchParams = {
    ...restFilters,
    arrival_date_start: arrival_date_start
      ? new Date(arrival_date_start)
      : undefined,
    departure_date_end: departure_date_end
      ? new Date(departure_date_end)
      : undefined,
    departure_date_start: departure_date_start
      ? new Date(departure_date_start)
      : undefined,
    arrival_date_end: arrival_date_end ? new Date(arrival_date_end) : undefined,
    orderBy,
    orderDirection: orderDirection ?? 'DESC',
    limit,
    page,
  }

  try {
    const { data }: AxiosResponse<DTOs.PaginatedScheduleListDto> = yield call(
      api.getScheduleList,
      produce(params, (draft) => {
        if (draft.limit && page) {
          draft.limit = draft.limit * page
        }
      }),
    )

    yield put(actions.setScheduleDataAction(data))
  } catch (error) {
    console.error(error)

    yield put(actions.getScheduleFailureAction(error))
  }
}

export default function* watchScheduleSaga(): Generator {
  yield takeLatest(
    ScheduleActionTypes.GetScheduleDataFirstPage,
    getInitialScheduleDataSaga,
  )

  yield takeLatest(
    ScheduleActionTypes.GetScheduleDataNextPage,
    getMoreScheduleDataSaga,
  )

  yield takeLatest(
    ScheduleActionTypes.StartSetScheduleFilters,
    setScheduleFiltersSaga,
  )

  yield takeLatest(
    ScheduleActionTypes.StartSetScheduleSort,
    setScheduleSortSaga,
  )

  yield takeLatest(
    ScheduleActionTypes.ReloadScheduleList,
    reloadScheduleListSaga,
  )

  yield takeLatest(
    ScheduleActionTypes.GetAllScheduleData,
    getAllScheduleDataSaga,
  )
}
